import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "visible_off",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1759",
      "data-name": "Rechteck 1759",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline3",
      d: "M517,94.5a10.361,10.361,0,0,0-3.9.774l1.57,1.571A8.211,8.211,0,0,1,517,96.5c3.278,0,6.389,1.989,8.665,5.5a14.332,14.332,0,0,1-2.709,3.128l1.427,1.427A17.1,17.1,0,0,0,528,102h0C525.193,97,521.1,94.5,517,94.5Z",
      transform: "translate(-505 -90)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline2",
      d: "M519.327,107.155A8.211,8.211,0,0,1,517,107.5c-3.278,0-6.389-1.989-8.665-5.5a14.332,14.332,0,0,1,2.709-3.128l-1.427-1.427A17.1,17.1,0,0,0,506,102h0c2.807,5,6.9,7.5,11,7.5a10.361,10.361,0,0,0,3.9-.774Z",
      transform: "translate(-505 -90)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "Pfad_474",
      "data-name": "Pfad 474",
      d: "M525.707,109.293l-5.273-5.273A3.957,3.957,0,0,0,521,102a4,4,0,0,0-4-4,3.957,3.957,0,0,0-2.02.566l-5.273-5.273a1,1,0,0,0-1.414,1.414l5.273,5.273A3.957,3.957,0,0,0,513,102a4,4,0,0,0,4,4,3.957,3.957,0,0,0,2.02-.566l5.273,5.273a1,1,0,0,0,1.414-1.414ZM517,100a2,2,0,0,1,2,2,1.959,1.959,0,0,1-.075.511l-2.436-2.436A1.96,1.96,0,0,1,517,100Zm0,4a2,2,0,0,1-2-2,1.959,1.959,0,0,1,.075-.511l2.436,2.436A1.96,1.96,0,0,1,517,104Z",
      transform: "translate(-505 -90)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}