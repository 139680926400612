import { InputStyleInterface } from "./input_interfaces";
import { metaHandlerDefaultStyle } from "./metaHandler/meta_handler_default_style";

export const inputDefaultStyle: InputStyleInterface = {
    labelColor: "grey-darker",
    backgroundColor: "white-dark",
    backgroundHoverColor: "grey-lightest",
    backgroundFocusColor: "grey-lightest",
    inputTextColor: "black",
    ...metaHandlerDefaultStyle,
};
