
import { computed, ComputedRef, defineComponent, onUnmounted, ref } from "vue";
import IconVisible from "../../icons/IconVisible.vue";
import IconVisibleOff from "../../icons/IconVisibleOff.vue";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { InputStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import { getSpaceConfigStyle } from "../../space_config_style";
import { inputDefaultStyle } from "../input_default_style";
import { metaHandlerDefaultStyle } from "../metaHandler/meta_handler_default_style";
import { storeToRefs } from "pinia";
import { useHelpersStore } from "@/store/helpers";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
export default defineComponent({
    name: "InputDefault",
    components: { IconVisible, IconVisibleOff, MetaHandler },
    props: {
        /**
         * id is necessary to uniquely identify this input
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * optional MetaID to also listen to for metaInformation
         */
        metaId: {
            type: String,
            required: false,
        },
        /**
         * specifies the name of the HTML Input element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * HTML Input type
         * only use: text, number, password
         */
        type: {
            type: String,
            default: "text",
            validator: (value: string) => {
                return ["text", "number", "password"].includes(value);
            },
        },
        /**
         * The placeholder shown in the input
         */
        placeholder: {
            type: [String, Number],
            required: false,
        },
        /**
         * displays the eye toggle for the password
         */
        hasPasswordVisibilityToggle: {
            type: Boolean,
            default: true,
        },
        /**
         * If the input type is number, there is a posibility to use a minimum value
         */
        min: {
            type: [String, Number],
            required: false,
        },
        /**
         * If the input type is number, there is a posibility to use a maximum value
         */
        max: {
            type: [String, Number],
            required: false,
        },
        /**
         * displays ' *' behind the input label
         */
        isRequired: {
            type: Boolean,
            default: false,
        },
        /**
         *adds an overlay if it is disabled
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         *adds an overlay if it is disabled
         */
        isInputDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * the label of the input, if it is undefined the input is getting centered
         */
        label: {
            type: String,
            required: false,
        },
        /**
         * the displayed value of the input
         */
        modelValue: {
            required: true,
        },
        /**
         * if this is set the input saves space for the bottom meta container and the message can get displayed
         */
        hasMeta: {
            type: Boolean,
            default: true,
        },

        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * {
         *      ! errorMessage?: string;
         *      ! infoMessage?: string;
         *      ! saveMessage?: string;
         * }
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },

        /**
         * Configuration Object for Input Style with
         * following attributes:
         * {
         *      ! labelColor?: string;
         *      ! backgroundColor?: string;
         *      ! backgroundFocusColor?: string;
         *      ! backgroundHoverColor?: string;
         *      ! inputTextColor?: string;
         *      ! errorColor?: string;
         *      ! saveColor?: string;
         *      ! infoColor?: string;
         * }
         */
        inputStyle: {
            type: Object as () => InputStyleInterface,
            default: {} as InputStyleInterface,
        },
        /**
         * tabindex for the input
         */
        tabIndex: {
            type: Number,
            default: 0,
        },
    },
    emits: ["change", "update:modelValue", "enter", "keydown"],
    setup(props, ctx) {
        const finalInputDefaultStyle: ComputedRef<InputStyleInterface> = computed(
            () => {
                return {
                    ...inputDefaultStyle,
                    ...metaHandlerDefaultStyle,
                    ...getSpaceConfigStyle()?.metaHandler,
                    ...getSpaceConfigStyle()?.inputDefault,
                    ...props.inputStyle,
                };
            }
        );
        const { errorMessages } = storeToRefs(useHelpersStore());
        const { removeErrorMessage } = useHelpersStore();
        const inputMessage = computed(() => {
            const foundError = errorMessages.value?.find(
                (element: any) => element.id == props.id
            );
            return foundError;
        });
        const inputElement = ref<HTMLInputElement>();
        const IsPasswordVisible = ref<boolean>(false);
        /**
         * this function emits enter if the Enter key is getting pressed
         *
         * if the input type is 'number' and there is a key up or key down event
         * the number is getting increased or decreased
         */
        function handleKeyDownEvent(e: KeyboardEvent): void {
            ctx.emit("keydown", e);
            if (e.key == "Enter") {
                ctx.emit("enter");
                return;
            }
            if (props.type != "number") {
                return;
            }
            let target = e.target as HTMLInputElement;
            let newValueAsNumber = Number(target.value);
            if (e.key == "ArrowUp") {
                e.preventDefault();
                newValueAsNumber++;
            } else if (e.key == "ArrowDown") {
                e.preventDefault();
                newValueAsNumber--;
            } else {
                return;
            }
            target.value = formatStringByType(newValueAsNumber.toString());

            ctx.emit("update:modelValue", target.value);
        }
        function handleChangeEvent(e: Event): void {
            let target = e.target as HTMLInputElement;
            target.value = formatStringByType(target.value);

            ctx.emit("change", target.value);
        }
        function handleInputEvent(e: Event): void {
            let target = e.target as HTMLInputElement;
            target.value = formatStringByType(target.value);
            removeErrorMessage(props.id);
            ctx.emit("update:modelValue", target.value);
        }

        /**
         * if the type is 'number' and the string includes a non numeric char
         * it is getting removed
         * and a '-' should only be allowed at the beginning
         *
         * @return string - the formated string
         */
        function formatStringByType(oldValue: string): string {
            let newValue = oldValue;
            if (props.type == "number") {
                newValue = newValue.replace(/(?!^)-/g, "").replace(/[^[^0-9,.-]+/g, "");
                const newValueAsNumber = Number(newValue);

                if (props.min && newValueAsNumber < props.min) {
                    newValue = props.min.toString();
                }
                if (props.max && newValueAsNumber > props.max) {
                    newValue = props.max.toString();
                }
            }
            return newValue;
        }

        const metaInformation = computed(() => {
            let metaInfos: MetaInformationInterface = {};
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (
                    (error.name == props.id || error.name == props.metaId) &&
                    error.value
                ) {
                    metaInfos.errorMessage = error.value;
                }
            });
            if (
                !metaInfos.errorMessage &&
                !metaInfos.infoMessage &&
                !metaInfos.saveMessage
            ) {
                return null;
            } else {
                return metaInfos;
            }
        });

        onUnmounted(() => {
            removeErrorMessage(props.id);
        });

        return {
            inputElement,
            IsPasswordVisible,
            handleChangeEvent,
            handleInputEvent,
            handleKeyDownEvent,
            finalInputDefaultStyle,
            inputMessage,
            metaInformation,
        };
    },
});
