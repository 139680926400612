import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "visible",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1757",
      "data-name": "Rechteck 1757",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("path", {
      id: "outline2",
      d: "M381,95c3.278,0,6.389,1.989,8.665,5.5-2.276,3.511-5.387,5.5-8.665,5.5s-6.389-1.989-8.665-5.5c2.276-3.511,5.387-5.5,8.665-5.5m0-2c-4.1,0-8.193,2.5-11,7.5h0c2.807,5,6.9,7.5,11,7.5s8.193-2.5,11-7.5h0c-2.807-5-6.9-7.5-11-7.5Z",
      transform: "translate(-369 -88)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2),
    _createElementVNode("path", {
      id: "outline1",
      d: "M381,98.5a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z",
      transform: "translate(-369 -88)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}