import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "input-message-span"
}
const _hoisted_3 = {
  key: 1,
  class: "input-message-span"
}
const _hoisted_4 = {
  key: 2,
  class: "input-message-span"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass(["input-message text-sm truncate w-full mt-1", `${_ctx.finalMetaHandlerStyle.metaPaddingClass} 
        text-${_ctx.inputMeta?.errorMessage
            ? _ctx.finalMetaHandlerStyle.errorColor
            : _ctx.inputMeta?.saveMessage
                ? _ctx.finalMetaHandlerStyle.saveColor
                : _ctx.finalMetaHandlerStyle.infoColor
        }`])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["transition-all", 
                _ctx.inputMeta?.errorMessage ||
                    _ctx.inputMeta?.saveMessage ||
                    _ctx.inputMeta?.infoMessage
                    ? 'opacity-100'
                    : 'opacity-0'
            ]),
      title: 
                _ctx.inputMeta?.errorMessage
                    ? _ctx.inputMeta.errorMessage
                    : _ctx.inputMeta?.saveMessage
                        ? _ctx.inputMeta.saveMessage
                        : _ctx.inputMeta?.infoMessage
                            ? _ctx.inputMeta.infoMessage
                            : '⠀'
            
    }, _toDisplayString(_ctx.inputMeta?.errorMessage
                    ? _ctx.inputMeta.errorMessage
                    : _ctx.inputMeta?.saveMessage
                        ? _ctx.inputMeta.saveMessage
                        : _ctx.inputMeta?.infoMessage
                            ? _ctx.inputMeta.infoMessage
                            : "⠀"), 11, _hoisted_1),
    (_ctx.inputMeta?.saveMessage && !_ctx.inputMeta.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "."))
      : _createCommentVNode("", true),
    (_ctx.inputMeta?.saveMessage && !_ctx.inputMeta.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "."))
      : _createCommentVNode("", true),
    (_ctx.inputMeta?.saveMessage && !_ctx.inputMeta.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, "."))
      : _createCommentVNode("", true)
  ], 2))
}