import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "relative group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["tabindex", "id", "name", "value", "placeholder", "min", "type", "max", "disabled", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_visible_off = _resolveComponent("icon-visible-off")!
  const _component_icon_visible = _resolveComponent("icon-visible")!
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: _normalizeClass(["absolute top-1.5 left-0 pl-4 transition-colors w-full font-regular text-sm truncate select-none", `text-${!_ctx.inputMessage
            ? _ctx.finalInputDefaultStyle.labelColor
            : _ctx.inputMessage.errorMessage
                ? _ctx.finalInputDefaultStyle.errorColor
                : _ctx.inputMessage.saveMessage
                    ? _ctx.finalInputDefaultStyle.saveColor
                    : _ctx.inputMessage.infoMessage
                        ? _ctx.finalInputDefaultStyle.infoColor
                        : _ctx.finalInputDefaultStyle.labelColor
            } ${_ctx.isInputDisabled ? 'cursor-pointer' : ''}`])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ], 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      tabindex: _ctx.isDisabled ? -1 : _ctx.tabIndex,
      ref: "inputElement",
      class: _normalizeClass(["w-full truncate outline-none text-md transition-colors pl-4 rounded-md", [
                `bg-${_ctx.finalInputDefaultStyle.backgroundColor} text-${_ctx.finalInputDefaultStyle.inputTextColor}`,
                _ctx.isDisabled
                    ? `bg-opacity-70 cursor-default text-opacity-70`
                    : `group-hover:bg-${_ctx.finalInputDefaultStyle.backgroundHoverColor} focus:bg-${_ctx.finalInputDefaultStyle.backgroundFocusColor}`,
                _ctx.isInputDisabled ? 'cursor-pointer' : '',
                _ctx.label ? 'pt-6 pb-1.5' : 'pt-2 pb-2',
                _ctx.$slots.append || (_ctx.type == 'password' && _ctx.hasPasswordVisibilityToggle)
                    ? 'pr-10'
                    : 'pr-3',
            ]]),
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      min: _ctx.min,
      type: 
                _ctx.type == 'number'
                    ? 'text'
                    : _ctx.IsPasswordVisible &&
                        _ctx.type == 'password' &&
                        _ctx.hasPasswordVisibilityToggle
                        ? 'text'
                        : _ctx.type
            ,
      max: _ctx.max,
      disabled: _ctx.isInputDisabled || _ctx.isDisabled,
      required: _ctx.isRequired,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInputEvent && _ctx.handleInputEvent(...args))),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeEvent && _ctx.handleChangeEvent(...args))),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDownEvent && _ctx.handleKeyDownEvent(...args)))
    }, null, 42, _hoisted_4),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute right-3 top-3.5 h-6 w-6 flex items-center justify-center overflow-hidden", 
                _ctx.isDisabled
                    ? ' cursor-default opacity-70'
                    : _ctx.type == 'password'
                        ? ' cursor-pointer '
                        : 'cursor-pointer'
            ]),
      onClick: _cache[3] || (_cache[3] = 
                () => {
                    if (_ctx.type == 'password' && _ctx.hasPasswordVisibilityToggle)
                        _ctx.IsPasswordVisible = !_ctx.IsPasswordVisible;
                    _ctx.$refs.inputElement.focus();
                }
            )
    }, [
      (
                    _ctx.IsPasswordVisible && _ctx.type == 'password' && _ctx.hasPasswordVisibilityToggle
                )
        ? (_openBlock(), _createBlock(_component_icon_visible_off, { key: 0 }))
        : (
                    _ctx.IsPasswordVisible == false &&
                    _ctx.type == 'password' &&
                    _ctx.hasPasswordVisibilityToggle
                )
          ? (_openBlock(), _createBlock(_component_icon_visible, { key: 1 }))
          : _renderSlot(_ctx.$slots, "append", { key: 2 }, undefined, true)
    ], 2),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 1,
          inputMeta: _ctx.metaInformation,
          metaStyle: _ctx.inputStyle
        }, null, 8, ["inputMeta", "metaStyle"]))
      : _createCommentVNode("", true)
  ]))
}