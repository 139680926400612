
import { getSpaceConfigStyle } from "@/components/space_config_style";
import { computed, ComputedRef, defineComponent } from "vue";
import {
    InputMetaInterface,
    InputMetaStyleInterface,
} from "./meta_handler_interfaces";
import { metaHandlerDefaultStyle } from "./meta_handler_default_style";
export default defineComponent({
    name: "MetaHandler",
    props: {
        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * {
         *      errorMessage?: string;
         *      infoMessage?: string;
         *      saveMessage?: string;
         * }
         *
         * ! infoMessage: if the input has a info message it is getting displayed under the input
         * ! saveMessage:  if the input has a save message it is getting  displayed under the input
         * and the label color changes to green
         * ! errorMessage: if the input has a error message it is getting displayed under the input
         * and the label color changes to red
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },
        /**
         * Configuration Object for Meta Style with
         * following attributes:
         * {
         *      ! errorColor?: string;
         *      ! saveColor?: string;
         *      ! infoColor?: string;
         * }
         */
        metaStyle: {
            type: Object as () => InputMetaStyleInterface,
            default: {} as InputMetaStyleInterface,
        },
    },
    setup(props) {
        const finalMetaHandlerStyle: ComputedRef<InputMetaStyleInterface> =
            computed(() => {
                return {
                    ...metaHandlerDefaultStyle,
                    ...getSpaceConfigStyle()?.metaHandler,
                    ...props.metaStyle,
                };
            });
        return {
            finalMetaHandlerStyle,
        };
    },
});
